import React from "react";
import { useState } from "react";
import {
  Box,
  Text,
  Heading,
  Button,
  useDisclosure,
  Select,
  Tr,
  Td,
  useToast,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import ModalForm from "../ModalForm";
import axios from "axios";
import { useAuth } from "../auth/AuthProvider";

function Comment({ id, message, created_at }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { token } = useAuth();

  const modalData = [
    { key: "message", label: "Message", value: message, type: "text" },
  ];

  const handleEdit = (data) => {
    // console.log(data);
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/comments/${id}`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // TODO: refactor toasting
        onClose();
        toast({
          title: "Comment successfully edited",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        onClose();
        toast({
          title: "Failed to edit comment",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error editing comment: ", err);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/comments/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // TODO: refactor toasting
        onClose();
        toast({
          title: "Comment successfully deleted",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        onClose();
        toast({
          title: "Failed to delete comment",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error deleting comment: ", err);
      });
  };

  return (
    <Tr>
      <Td>{message}</Td>
      <Td>{created_at?.split("T")[0]}</Td>
      <Td>
        <Flex gridGap={4}>
          <Button size="sm" onClick={onOpen}>
            <EditIcon />
          </Button>
          <ModalForm
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleEdit}
            title="Edit Comment"
            data={modalData}
          />
          <IconButton
            colorScheme="red"
            aria-label="Delete"
            icon={<DeleteIcon />}
            onClick={handleDelete}
            size="sm"
          />
        </Flex>
      </Td>
    </Tr>
  );
}

export default Comment;
