import { Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react";
import { BsChevronDown } from "react-icons/bs";

const SortSelector = ({ sortOptions, selectedSort, onSelect }) => {
  return (
    <Menu zIndex={2}>
      <MenuButton as={Button} rightIcon={<BsChevronDown />} colorScheme="gray">
        {selectedSort
          ? "Sort by: " +
            sortOptions.find((option) => option.value === selectedSort)?.label
          : "Sort"}
      </MenuButton>
      <MenuList zIndex={2}>
        <MenuItem key={0} onClick={() => onSelect(null)}>
          Sort
        </MenuItem >
        {sortOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={() => onSelect(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default SortSelector;