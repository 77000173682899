import { useState } from "react";
import ArrearsApp from "../applications/ArrearsApp";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Center,
  Text,
  Box,
  TableContainer,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import SearchBar from "../SearchBar";
import useAPI from "../../hooks/useAPI";
import SortSelector from "../selectors/SortSelector";
import OrderSelector from "../selectors/OrderSelector";
import CustomSpinner from "../CustomSpinner";
import axios from "axios";
import CallSheetPopup from "../CallSheetPopup";
import { useAuth } from "../auth/AuthProvider";

function ArrearPanel() {
  const [query, setQuery] = useState({});
  const [commentsAdded, setCommentsAdded] = useState(0);
  const [generate, setGenerate] = useState(0);
  const [callLogs, setCallLogs] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { token } = useAuth();

  const onAddComment = () => {
    setCommentsAdded((prevCount) => prevCount + 1);
  };

  const {
    data: apps,
    error,
    loading,
  } = useAPI(
    "/applications/arrears",
    {
      params: {
        ...query,
      },
    },
    [query, generate]
  );

  const fetchCallLogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/comments/logs/week`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCallLogs(response.data);
      onOpen();
    } catch (error) {
      console.error("Error fetching call logs:", error);
    }
  };

  return (
    <>
      <Center my={2}>
        <Button
          size="lg"
          mb={4}
          isDisabled={loading || apps.length !== 0}
          onClick={() => {
            setGenerate(prev => prev + 1);
            setCommentsAdded(0);
          }}
        >
          Generate New Call Sheet
        </Button>
        <Button 
          size="lg" 
          mb={4}
          ml={4}
          onClick={() => {
            fetchCallLogs();
          }}
        >
          Call Logs
        </Button>
      </Center>
      <TableContainer
        borderRadius="xl"
        whiteSpace="normal"
        height="700px"
        overflowY="auto"
        position="relative"
      >
        <Table>
          <Thead position="sticky" top="0" zIndex={1}>
            <Tr>
              <Th>id</Th>
              <Th>Name</Th>
              <Th>Phone Number</Th>
              <Th>Amount Behind</Th>
              <Th>Last Paid</Th>
              <Th>Last Called</Th>
              <Th>Add Comment</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!loading &&
              apps &&
              apps.length > 0 &&
              apps.map((app, index) => (
                <ArrearsApp key={index} {...app} onAddComment={onAddComment} />
              ))}
          </Tbody>
        </Table>
        {loading && <CustomSpinner />}
        {!loading && apps && apps.length === 0 && (
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"Center"}
            position={"absolute"}
            top={0}
          >
            <Text fontSize="2xl" fontWeight="semibold" color="gray.500">
              No applications
            </Text>
          </Box>
        )}
      </TableContainer>
      <CallSheetPopup isOpen={isOpen} onClose={onClose} callLogs={callLogs} />
    </>
  );
}

export default ArrearPanel;
