import React from "react";
import { useState } from "react";
import {
  Box,
  Text,
  Heading,
  Button,
  useDisclosure,
  Select,
  Tr,
  Td,
  useToast,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";
import ModalForm from "../ModalForm";
import { useAuth } from "../auth/AuthProvider";

function CompletedInvoice({ id, date, memo, amount, student_id, type }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { token } = useAuth();

  const typeLabels = {
    advance: "Advance",
    membership_fee: "Membership Fee",
    application_fee: "Application Fee",
    other: "Other",
  };

  const modalData = [
    { key: "date", label: "Date", value: date?.split("T")[0], type: "date" },
    { key: "memo", label: "Memo", value: memo, type: "text" },
    { key: "amount", label: "Amount", value: amount, type: "text" },
    {
      key: "type",
      label: "Type",
      value: type,
      type: "select",
      options: typeLabels,
    },
  ];

  const handleDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/invoices/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // TODO: refactor toasting
        toast({
          title: "Invoice successfully deleted",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast({
          title: "Failed to delete invoice",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error deleting invoice: ", err);
      });
  };

  const handleEdit = (data) => {
    // console.log(data);
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/invoices/${id}`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // TODO: refactor toasting
        onClose();
        toast({
          title: "Invoice successfully edited",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        onClose();
        toast({
          title: "Failed to edit invoice",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error editing invoice: ", err);
      });
  };

  return (
    <Tr>
      <Td>{id}</Td>
      <Td>{date?.split("T")[0]}</Td>
      <Td>{memo}</Td>
      <Td>{amount}</Td>
      <Td>{typeLabels[type]}</Td>
      <Td>
        <Flex gridGap={4}>
          <Button size="sm" onClick={onOpen}>
            <EditIcon />
          </Button>
          <ModalForm
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleEdit}
            title="Edit Invoice"
            data={modalData}
          />
          <IconButton
            colorScheme="red"
            aria-label="Delete"
            icon={<DeleteIcon />}
            onClick={handleDelete}
            size="sm"
          />
        </Flex>
      </Td>
    </Tr>
  );
}

export default CompletedInvoice;
