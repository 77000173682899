import { Link } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import { Tr, Td, Flex, Button, Textarea, useToast, Input } from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import useAPI from "../../hooks/useAPI";
import Comment from "../comments/Comment";
import AddComment from "../comments/NewComment";
import { useForm } from "react-hook-form";
import { useAuth } from "../auth/AuthProvider";
import CallHistoryCommentModal from "../CallHistoryCommentModal";

function CallHistoryApp({
    id,
    basicInformation_firstname,
    basicInformation_lastname,
    finances,
    since_last_call,
    last_call_date,
    call_unit,
    onAddComment,
    lastComment,
    phone_number
}) {
    
    const [added, setAdded] = useState(false);
    const { token } = useAuth();
    const toast = useToast();
    const { register, handleSubmit, reset } = useForm();
    const {
        isOpen: isOpenComment,
        onOpen: onOpenComment,
        onClose: onCloseComment,
    } = useDisclosure();
    const [commentsQuery, setCommentsQuery] = useState({});
    const { data: commentsData } = useAPI(
    `/comments`,
    {
      params: { student_id: id, ...commentsQuery },
    },
    [commentsQuery]
    );
    const comments = commentsData?.results;
    const mostRecentComment = comments?.length > 0 ? comments[0].message : null;  
    const mostRecentCommentDisplay = mostRecentComment 
    ? (mostRecentComment.length > 50 ? mostRecentComment.substring(0, 50) + "..." : mostRecentComment)
    : null;


        return (
            <>
                <Tr>
                    <Td>{id}</Td>
                    <Td>{basicInformation_firstname} {basicInformation_lastname}</Td>
                    <Td>{phone_number}</Td>
                    <Td>{finances.days_since_last_deposit + " days ago"}</Td>
                    <Td>{last_call_date.split("T")[0]}</Td>
                    <Td>{since_last_call + " " + call_unit + " ago"}</Td>
                    <Td>
                        <Flex gridGap={2} onClick={onOpenComment}>
                        {mostRecentCommentDisplay ? <span>{mostRecentCommentDisplay}</span> : <span>No comments</span>}
                        </Flex>
                    </Td>
                </Tr> 
                <CallHistoryCommentModal 
                    isOpen={isOpenComment}
                    onClose={onCloseComment}
                    comment={mostRecentComment}>
                </CallHistoryCommentModal>  
            </>
        );
}

export default CallHistoryApp;
