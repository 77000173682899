import { useState } from "react";
import OpenApp from "../applications/OpenApp";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Center,
  Text,
  Box,
  TableContainer,
  HStack,
  Spinner,
  Flex,
  Heading,
} from "@chakra-ui/react";
import SearchBar from "../SearchBar";
import useAPI from "../../hooks/useAPI";
import SortSelector from "../selectors/SortSelector";
import OrderSelector from "../selectors/OrderSelector";
import CustomSpinner from "../CustomSpinner";

function OpenPanel() {
  const [query, setQuery] = useState({
    status: "open",
    sort: "date_applied",
    order: "desc",
  });

  const {
    data: { results: apps },
    error,
    loading,
  } = useAPI(
    "/applications",
    {
      params: {
        ...query,
      },
    },
    [query]
  );

  return (
    <>
      <HStack spacing={4} mb={4}>
        <SearchBar
          onSearch={(input) => setQuery({ ...query, search: input })}
          placeholder={"Search open applications"}
        />
        <SortSelector
          sortOptions={[
            { value: "id", label: "ID" },
            { value: "date_applied", label: "Date Applied" },
            { value: "name", label: "Name" },
          ]}
          selectedSort={query.sort}
          onSelect={(sort) => {
            if (sort === null) {
              setQuery({ ...query, sort, order: null });
            } else {
              setQuery({ ...query, sort });
            }
          }}
        />
        <OrderSelector
          selectedOrder={query.order}
          onSelect={(order) => setQuery({ ...query, order })}
        />
      </HStack>
      <TableContainer
        borderRadius="xl"
        whiteSpace="normal"
        height="700px"
        overflowY="auto"
        position="relative"
      >
        <Table>
          <Thead position="sticky" top="0" zIndex={1}>
            <Tr>
              <Th>Date Applied</Th>
              <Th>Name</Th>
              <Th>MAT</Th>
              <Th>Phone Number</Th>
              <Th>Invoices</Th>  {/* new add */}
              <Th>School</Th>
              <Th>First Invoice Date</Th>
              <Th>Tuition Amount</Th>
              <Th>Arrears Amount</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!loading &&
              apps &&
              apps.length > 0 &&
              apps.map((app, index) => <OpenApp key={index} {...app} />)}
          </Tbody>
        </Table>
        {loading && <CustomSpinner />}
        {!loading && apps && apps.length === 0 && (
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"Center"}
            position={"absolute"}
            top={0}
          >
            <Text fontSize="2xl" fontWeight="semibold" color="gray.500">
              No applications
            </Text>
          </Box>
        )}
      </TableContainer>
    </>
  );
}

export default OpenPanel;
