import { Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react";
import { BsChevronDown } from "react-icons/bs";

const OrderSelector = ({selectedOrder, onSelect}) => {
  const sortOrders = [
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ];

  return (
    <Menu zIndex={2}>
      <MenuButton as={Button} rightIcon={<BsChevronDown />} colorScheme="gray">
        {sortOrders.find(order => order.value === selectedOrder)?.label || "Order"}
      </MenuButton>
      <MenuList zIndex={2}>
        {sortOrders.map((order) => (
          <MenuItem key={order.value} onClick={() => onSelect(order.value)}>{order.label}</MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default OrderSelector;