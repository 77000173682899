import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, VStack } from '@chakra-ui/react';
function CallSheetPopup({ isOpen, onClose, callLogs }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Weekly Call Logs</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="start">
            {callLogs.length > 0 ? (
              callLogs.map((log, index) => (
                <Text key={index}>
                  {log.start_date} to {log.end_date}: {log.calls} calls
                </Text>
              ))
            ) : (
              <Text>No call logs available.</Text>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CallSheetPopup;