import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Image,
  IconButton,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import logo from "../assets/logo.jpeg";
import { useRef } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useAuth } from "../Components/auth/AuthProvider";

const Login = () => {
  const auth = useAuth();
  const { register, handleSubmit } = useForm();
  const { isOpen, onToggle } = useDisclosure();
  const toast = useToast();
  const inputRef = useRef(null);

  const onSubmit = (data) => {
    auth.loginAction(data, (err) => {
      toast({
        title: "Failed to login",
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error logging in: ", err);
    });
  };

  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  return (
    <Container maxW="lg" py="24" px="8">
      <Box p="10" boxShadow="md" borderRadius="xl">
        <Image
          src={logo}
          width="75px"
          height="75px"
          display="block"
          mx="auto"
        />
        <Heading
          size="md"
          textAlign="center"
          mb={4}
          mt={7}
          style={{ fontFamily: "Raleway" }}
        >
          Admin Login
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="8">
            <Stack spacing="6">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input type="email" {...register("email")} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">Password</FormLabel>
                <InputGroup>
                  <InputRightElement>
                    <IconButton
                      variant="text"
                      aria-label={isOpen ? "Mask password" : "Reveal password"}
                      icon={isOpen ? <HiEyeOff /> : <HiEye />}
                      onClick={onClickReveal}
                    />
                  </InputRightElement>
                  <Input
                    ref={inputRef}
                    type={isOpen ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    {...register("password")}
                  />
                </InputGroup>
              </FormControl>
            </Stack>
            <Button type="submit">Sign in</Button>
          </Stack>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
