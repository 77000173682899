import React, { useState, useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Center,
  Text,
  Box,
  Button,
  TableContainer,
  Input,
  useToast,
} from "@chakra-ui/react";
import SearchBar from "../SearchBar";
import IncompleteDeposit from "../deposits/NewDeposit";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import useAPI from "../../hooks/useAPI";
import CustomSpinner from "../CustomSpinner";
import { useAuth } from "../auth/AuthProvider";

function NewPanel() {
  const toast = useToast();
  const fileInputRef = useRef(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const { token } = useAuth();
  const [data, setData] = useState([]);



  const handleFileUpload = (event) => {
    setLoadingFile(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/invoices/upload_csv`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast({
          title: "File successfully uploaded",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setData(res.data); // Set the data from the response
        setLoadingFile(false);

      })
      .catch((err) => {
        toast({
          title: "Failed to upload file",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Failed to upload file: ", err);
        setLoadingFile(false);
      });
  };
  return (
    <>
      <Center my={4}>
        <Input
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          hidden
          ref={fileInputRef}
        />
        <Button
          leftIcon={<FiUpload />}
          colorScheme="gray"
          onClick={() => fileInputRef.current.click()}
          size="lg"
          mb={4}
        >
          Upload CSV
        </Button>
      </Center>
      <TableContainer
        borderRadius="xl"
        whiteSpace="normal"
        height="660px"
        overflowY="auto"
        position="relative"
      >
        <Table>
          <Thead position="sticky" top="0" zIndex={1}>
            <Tr>
              <Th>Student MAT</Th>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Memo</Th>
              <Th>Amount</Th>
              <Th>Date</Th>
              <Th>Type</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!loadingFile &&
                data &&
                data.length > 0 &&
                data.map((item, index) => (
                    <Tr key={index}>
                    <Td>{item.student_mat}</Td>
                    <Td>{item.student_name}</Td>
                    <Td>{item.student_email}</Td>
                    <Td>{item.memo}</Td>
                    <Td>{item.amount}</Td>
                    <Td>{new Date(item.date).toLocaleDateString()}</Td>
                    <Td>{item.type}</Td>
                    </Tr>
                ))}
          </Tbody>
        </Table>
        {(loadingFile) && <CustomSpinner />}
        {!loadingFile &&
          data &&
          data.length === 0 && (
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"Center"}
              position={"absolute"}
              top={0}
            >
              <Text fontSize="2xl" fontWeight="semibold" color="gray.500">
                No invoices present
              </Text>
            </Box>
          )}
      </TableContainer>
    </>
  );
}

export default NewPanel;
