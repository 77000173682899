import { useState } from "react";
import CallHistoryApp from "../applications/CallHistoryApp";

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    useToast,
    Center,
    Text,
    Box,
    TableContainer,
    Button,
    useDisclosure,
  } from "@chakra-ui/react";
  import SearchBar from "../SearchBar";
  import useAPI from "../../hooks/useAPI";
  import SortSelector from "../selectors/SortSelector";
  import OrderSelector from "../selectors/OrderSelector";
  import CustomSpinner from "../CustomSpinner";
  import axios from "axios";
  import CallSheetPopup from "../CallSheetPopup";
  import { useAuth } from "../auth/AuthProvider";

function CallHistory() {
    const [query, setQuery] = useState({});
    const [commentsAdded, setCommentsAdded] = useState(0);
    const [generate, setGenerate] = useState(0);
    const [callLogs, setCallLogs] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { token } = useAuth();
    const toast = useToast();
    
    const {
        data: apps,
        error,
        loading,
      } = useAPI(
        "/applications/call-history",
        {
          params: {
            ...query,
          },
        },
        [query, generate]
      );
    


    return (
        <TableContainer
            borderRadius="xl"
            whiteSpace="normal"
            height="700px"
            overflowY="auto"
            position="relative"
        >
        <Table>
            <Thead position="sticky" top="0" zIndex={1}>
                <Tr>
                    <Th>id</Th>
                    <Th>Name</Th>
                    <Th>Phone Number</Th>
                    <Th>Last Paid</Th>
                    <Th>Last Call Date</Th>
                    <Th>Since Last Call</Th>
                    <Th>Last Comment</Th>
                </Tr>
            </Thead>
            <Tbody>
                {!loading &&
                apps &&
                apps.length > 0 &&
                apps.map((app, index) => (
                    <CallHistoryApp key={index} {...app}/>
                ))}
            </Tbody>
        </Table>
        {loading && <CustomSpinner />}
    </TableContainer>
    );
}

export default CallHistory;