import React from "react";
import { useState } from "react";
import {
  Box,
  Text,
  Heading,
  Button,
  Input,
  Tr,
  Td,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useAuth } from "../auth/AuthProvider";

function NewComment({ student_id }) {
  const toast = useToast();
  const { register, handleSubmit } = useForm();
  const { token } = useAuth();

  const handleAdd = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/comments`,
        { student_id, ...data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // TODO: refactor toasting
        toast({
          title: "Comment successfully added",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast({
          title: "Failed to add comment",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error adding comment: ", err);
      });
  };

  return (
    <Tr>
      <Td>
        <Input {...register("message")} placeholder="Message" />
      </Td>
      <Td></Td>
      <Td>
        <Button size="sm" onClick={handleSubmit(handleAdd)}>
          Add
        </Button>
      </Td>
    </Tr>
  );
}

export default NewComment;
