import { Link } from "react-router-dom";
import { Tr, Td, Flex, Button, Textarea, useToast, Input } from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../auth/AuthProvider";

function ArrearsApp({
  id,
  basicInformation_firstname,
  basicInformation_lastname,
  finances,
  since_last_call,
  score,
  onAddComment,
  phone_number
}) {

  const toast = useToast();
  const { register, handleSubmit } = useForm();
  const { token } = useAuth();

  const [comment, setComment] = useState('')
  const [added, setAdded] = useState(false)

  const submitComment = async () => {
    axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/comments`,
      {
        student_id: id,
        message: comment
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      toast({
        title: `Comment Successfully Added`,
        position: "top",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setComment('')
      onAddComment()
      setAdded(true)
    })
    .catch((err) => {
      toast({
        title: "Failed to add comment",
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error adding comment: ", err);
    });
  }

  const handleInputChange = (e) => {
    setComment(e.target.value);
  };


  return (
    <Tr
      _hover={{ backgroundColor: "gray.100" }}
      cursor="pointer"
      backgroundColor={!added ? "white" : "gray.200"}
    >
      <Td>{id}</Td>
      <Td>{basicInformation_firstname + " " + basicInformation_lastname}</Td>
      <Td>{phone_number}</Td>
      <Td>{finances.deposited_minus_expected}</Td>
      <Td>{finances.days_since_last_deposit + " days ago"}</Td>
      <Td>{since_last_call + " days ago"}</Td>
      <Td>
        <Flex gridGap={2} justifyContent="center" alignItems="center">
          <Input
            placeholder="add comments"
            value={comment} 
            onChange={handleInputChange}
            isDisabled={added}
          >
          </Input>
          <Button isDisabled={comment.trim() === '' || added} onClick={handleSubmit(() => submitComment())}>
            Add
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
}

export default ArrearsApp;
