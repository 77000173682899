import { Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react";
import { BsChevronDown } from "react-icons/bs";

const FilterSelector = ({ filterName, filterOptions, selectedFilter, onSelect }) => {
  return (
    <Menu zIndex={2}>
      <MenuButton as={Button} rightIcon={<BsChevronDown />} colorScheme="gray">
        {selectedFilter
          ? filterOptions[selectedFilter]
          : filterName}
      </MenuButton>
      <MenuList maxHeight="400px" overflowY="auto" zIndex={2}>
        <MenuItem key={0} onClick={() => onSelect(null)}>
          {filterName}
        </MenuItem>
        {Object.keys(filterOptions).map((option) => (
          <MenuItem key={option} onClick={() => onSelect(option)}>
            {filterOptions[option]}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default FilterSelector;
