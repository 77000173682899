import React from "react";
import { useState } from "react";
import {
  Box,
  Text,
  Heading,
  Button,
  Flex,
  useDisclosure,
  Select,
  Tr,
  Td,
  useToast,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";
import ModalForm from "../ModalForm";
import { useAuth } from "../auth/AuthProvider";

//  TODO: combine props into an object
function IncompleteDeposit({
  id,
  date,
  memo,
  amount,
  reference_id,
  student_mat,
  type,
}) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit } = useForm();
  const { token } = useAuth();

  const handleDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/deposits/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // TODO: refactor toasting
        toast({
          title: "Deposit successfully deleted",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast({
          title: "Failed to delete deposit",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error deleting deposit: ", err);
      });
  };

  // TODO: make sure student MAT is not null!
  const resolveDeposit = (formData) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/deposits/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // TODO: refactor toasting
        toast({
          title: "Deposit successfully submitted",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          toast({
            title: "Invalid MAT Number",
            position: "top",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Failed to submit deposit",
            position: "top",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        console.error("Error submitting deposit: ", err);
      });
  };

  return (
    <Tr>
      <Td>{id}</Td>
      <Td>{date?.split("T")[0]}</Td>
      <Td>{memo}</Td>
      <Td>{amount}</Td>
      <Td>{reference_id}</Td>
      <Td>
        <Input {...register("student_mat")} placeholder="Student MAT" />
      </Td>
      <Td>
        <Flex gridGap={4}>
          <Button onClick={handleSubmit((data) => resolveDeposit(data))}>
            Submit
          </Button>
          <IconButton
            colorScheme="red"
            aria-label="Delete"
            icon={<DeleteIcon />}
            onClick={handleDelete}
          />
        </Flex>
      </Td>
    </Tr>
  );
}

export default IncompleteDeposit;
