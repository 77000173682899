import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(
    localStorage.getItem("jf_admin_portal") || ""
  );

  const navigate = useNavigate();
  const loginAction = async (data, onError) => {
    const formData = new FormData();
    formData.append("username", data.email);
    formData.append("password", data.password);

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/login`, formData)
      .then(async (res) => {
        setUser(res.data.user);
        setToken(res.data.access_token);
        localStorage.setItem("jf_admin_portal", res.data.access_token);
        navigate("/");
      })
      .catch(onError);
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("jf_admin_portal");
    navigate("../login");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
