import { useState, useEffect } from "react";
import Deposit from "../deposits/Deposit";
import Invoice from "../invoices/Invoice";
import Comment from "../comments/Comment";
import AddComment from "../comments/NewComment";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  TableContainer,
  Box,
  Center,
  Button,
  useToast,
  useDisclosure,
  HStack,
  Flex,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import axios from "axios";
import useAPI from "../../hooks/useAPI";
import { useAuth } from "../auth/AuthProvider";
import ModalForm from "../ModalForm";
import SearchBar from "../SearchBar";
import SortSelector from "../selectors/SortSelector";
import OrderSelector from "../selectors/OrderSelector";

const IndividualPanels = ({ id }) => {
  const [depositsQuery, setDepositsQuery] = useState({
    sort: "date",
    order: "asc",
  });
  const { data: depositsData } = useAPI(
    `/deposits`,
    {
      params: { student_id: id, ...depositsQuery },
    },
    [depositsQuery]
  );
  const deposits = depositsData?.results;

  const [invoicesQuery, setInvoicesQuery] = useState({
    sort: "date",
    order: "asc",
  });
  const { data: invoicesData } = useAPI(
    `/invoices`,
    {
      params: { student_id: id, ...invoicesQuery },
    },
    [invoicesQuery]
  );
  const invoices = invoicesData?.results;

  const transactions = [
    ...(deposits?.map((deposit) => ({
      ...deposit,
      type: "Deposit",
      amount: deposit.amount * -1,
    })) || []),
    ...(invoices?.map((invoice) => ({
      ...invoice,
      type: "Invoice",
    })) || []),
  ].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });

  let runningTotal = 0;
  const transactionsWithBalance = transactions.map((transaction) => {
    runningTotal += transaction.amount;
    return {
      ...transaction,
      balance: runningTotal,
    };
  });

  const [commentsQuery, setCommentsQuery] = useState({});
  const { data: commentsData } = useAPI(
    `/comments`,
    {
      params: { student_id: id, ...commentsQuery },
    },
    [commentsQuery]
  );
  const comments = commentsData?.results;

  const toast = useToast();
  const {
    isOpen: isOpenDepositAdd,
    onOpen: onOpenDepositAdd,
    onClose: onCloseDepositAdd,
  } = useDisclosure();
  const {
    isOpen: isOpenInvoiceAdd,
    onOpen: onOpenInvoiceAdd,
    onClose: onCloseInvoiceAdd,
  } = useDisclosure();
  const { token } = useAuth();

  const addDepositModal = [
    {
      key: "date",
      label: "Date",
      value: new Date().toISOString().split("T")[0],
      type: "date",
    },
    { key: "memo", label: "Memo", value: null, type: "text" },
    { key: "amount", label: "Amount", value: null, type: "text" },
    {
      key: "reference_id",
      label: "Reference No",
      value: null,
      type: "text",
    },
  ];

  const typeLabels = {
    advance: "Advance",
    membership_fee: "Membership Fee",
    application_fee: "Application Fee",
    other: "Other",
  };

  const addInvoiceModal = [
    {
      key: "date",
      label: "Date",
      value: new Date().toISOString().split("T")[0],
      type: "date",
    },
    { key: "memo", label: "Memo", value: null, type: "text" },
    { key: "amount", label: "Amount", value: null, type: "text" },
    {
      key: "type",
      label: "Type",
      value: null,
      type: "select",
      options: typeLabels,
    },
  ];

  const handleDepositAdd = (data) => {
    // console.log(data);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/deposits/single`,
        {
          student_id: id,
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // TODO: refactor toasting
        onCloseDepositAdd();
        toast({
          title: "Deposit successfully created",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        onCloseDepositAdd();
        toast({
          title: "Failed to create deposit",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error creating deposit: ", err);
      });
  };

  const handleInvoiceAdd = (data) => {
    // console.log(data);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/invoices/single`,
        {
          student_id: id,
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // TODO: refactor toasting
        onCloseInvoiceAdd();
        toast({
          title: "Invoice successfully created",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        onCloseInvoiceAdd();
        toast({
          title: "Failed to create invoice",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error creating invoice: ", err);
      });
  };

  return (
    <>
      <Tabs size={"md"}>
        <TabList mb={4}>
          <Tab>Deposits</Tab>
          <Tab>Invoices</Tab>
          <Tab>All</Tab>
          <Tab>Comments</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Center my={2}>
              <Button
                leftIcon={<AddIcon />}
                onClick={onOpenDepositAdd}
                colorScheme="gray"
                size="lg"
                mb={4}
              >
                Add Deposit
              </Button>
              <ModalForm
                isOpen={isOpenDepositAdd}
                onClose={onCloseDepositAdd}
                onSubmit={handleDepositAdd}
                title="Add Deposit"
                data={addDepositModal}
              />
              <HStack spacing={4} ml={12} mb={4}>
                <SortSelector
                  sortOptions={[
                    { value: "id", label: "ID" },
                    { value: "date", label: "Date" },
                  ]}
                  selectedSort={depositsQuery.sort}
                  onSelect={(sort) => {
                    if (sort === null) {
                      setDepositsQuery({ ...depositsQuery, sort, order: null });
                    } else {
                      setDepositsQuery({ ...depositsQuery, sort });
                    }
                  }}
                />
                <OrderSelector
                  selectedOrder={depositsQuery.order}
                  onSelect={(order) =>
                    setDepositsQuery({ ...depositsQuery, order })
                  }
                />
              </HStack>
            </Center>
            <TableContainer borderRadius="xl" whiteSpace="normal">
              <Box maxH={"700px"} overflowY="auto">
                <Table size="sm">
                  <Thead position="sticky" top="0" zIndex={1}>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Date</Th>
                      <Th>Memo</Th>
                      <Th>Amount</Th>
                      <Th>Ref No.</Th>
                      {/* <Th>Type</Th> */}
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {deposits?.map((deposit, index) => (
                      <Deposit key={deposit.id} {...{ ...deposit, student: null }} />
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <Center my={2}>
              <Button
                leftIcon={<AddIcon />}
                onClick={onOpenInvoiceAdd}
                colorScheme="gray"
                size="lg"
                mb={4}
              >
                Add Invoice
              </Button>
              <ModalForm
                isOpen={isOpenInvoiceAdd}
                onClose={onCloseInvoiceAdd}
                onSubmit={handleInvoiceAdd}
                title="Add Invoice"
                data={addInvoiceModal}
              />
              <HStack spacing={4} ml={12} mb={4}>
                <SortSelector
                  sortOptions={[
                    { value: "id", label: "ID" },
                    { value: "date", label: "Date" },
                  ]}
                  selectedSort={invoicesQuery.sort}
                  onSelect={(sort) => {
                    if (sort === null) {
                      setInvoicesQuery({ ...invoicesQuery, sort, order: null });
                    } else {
                      setInvoicesQuery({ ...invoicesQuery, sort });
                    }
                  }}
                />
                <OrderSelector
                  selectedOrder={invoicesQuery.order}
                  onSelect={(order) =>
                    setInvoicesQuery({ ...invoicesQuery, order })
                  }
                />
              </HStack>
            </Center>
            <TableContainer borderRadius="xl" whiteSpace="normal">
              <Box maxH={"700px"} overflowY="auto">
                <Table size="sm">
                  <Thead position="sticky" top="0" zIndex={1}>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Date</Th>
                      <Th>Memo</Th>
                      <Th>Amount</Th>
                      <Th>Type</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {invoices?.map((invoice, index) => (
                      <Invoice key={invoice.id} {...invoice} />
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <Center my={2}></Center>
            <TableContainer borderRadius="xl" whiteSpace="normal">
              <Box maxH={"700px"} overflowY="auto">
                <Table size="sm">
                  <Thead position="sticky" top="0" zIndex={1}>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Date</Th>
                      <Th>Memo</Th>
                      <Th>Amount</Th>
                      <Th>Type</Th>
                      <Th>Amount Remaining</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {transactionsWithBalance.map((transaction, index) => (
                      <Tr key={transaction.id}>
                        <Td>{transaction.id}</Td>
                        <Td>{transaction.date?.split("T")[0]}</Td>
                        <Td>{transaction.memo}</Td>
                        <Td>{transaction.amount}</Td>
                        <Td>{transaction.type}</Td>
                        <Td>{transaction.balance}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <TableContainer borderRadius="xl" whiteSpace="normal">
              <Table>
                <Thead>
                  <Tr>
                    <Th>Message</Th>
                    <Th>Date</Th>
                    <Th></Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <AddComment student_id={id} />
                  {comments?.map((comment, index) => (
                    <Comment key={comment.id} {...comment} />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default IndividualPanels;
