import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../Components/auth/AuthProvider";

const useAPI = (endpoint, requestConfig, deps) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { token, logOut } = useAuth();

  useEffect(
    () => {
      setLoading(true);
      const source = axios.CancelToken.source();

      const config = {
        ...requestConfig,
        headers: {
          ...requestConfig?.headers,
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      };

      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, config)
        .then((res) => {
          setData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          if (axios.isCancel(err)) return;
          setLoading(false);
          setError(err.message);
          if (err.response && err.response.status === 401)
            logOut();
        });

      return () => source.cancel();
    },
    deps ? [...deps] : []
  );

  return { data, error, loading, setData };
};

export default useAPI;