import React from "react";
import Applications from "./pages/Applications";
import Deposits from "./pages/Deposits";
import IndividualApp from "./pages/IndividualApp";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import theme from "./theme";
import Login from "./pages/Login";
import AuthProvider from "./Components/auth/AuthProvider";
import PrivateRoute from "./Components/auth/PrivateRoute";
import Invoices from "./pages/Invoices";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Applications />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/applications" element={<Applications />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/deposits" element={<Deposits />} />
            </Route>
            <Route element = {<PrivateRoute />} >
            <Route path="/upload_invoices" element={<Invoices />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/applications/:id" element={<IndividualApp />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
}

export default App;
