import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Text,
  Heading,
  Button,
  Flex,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const ModalForm = ({ isOpen, onClose, onSubmit, title, data }) => {
  const { register, handleSubmit, reset, setValue } = useForm();

  useEffect(() => {
    // Reset the form when default values change
    reset();
    // Set the default values using setValue
    data.forEach((item) => {
      setValue(item.key, item.value);
    });
  }, [data]);

  // convert empty strings to null, otherwise backend will complain
  const processFormData = (formData) => {
    for (const key in formData) if (formData[key] === "") formData[key] = null;
    return formData;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit((data) => onSubmit(processFormData(data)))}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data.map((item) => {
              return (
                <Box key={item.key}>
                  <Text mb="3" fontWeight="semibold">
                    {item.label}
                  </Text>
                  {item.type == "select" ? (
                    <Select {...register(item.key)} mb="3">
                      {Object.keys(item.options).map((key) => (
                        <option key={key} value={key}>
                          {item.options[key]}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      {...register(item.key)}
                      placeholder={item.label}
                      type={item.type}
                      mb="3"
                    />
                  )}
                </Box>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={4} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default ModalForm;
