import React from "react";
import { useState } from "react";
import {
  Box,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
} from "@chakra-ui/react";
import CompletedPanel from "../Components/panels/CompletedDeposits";
import NewPanel from "../Components/panels/NewDeposits";
import NavBar from "../Components/Navbar";

function Deposits() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <NavBar activeName={"Deposits"} />
      <Box m={6}>
        {/* <Heading size="lg" mb={8} fontWeight="semibold" color="black">
          Deposits
        </Heading> */}
        {/* <Tabs
          onChange={(index) => setActiveTab(index)}
          index={activeTab}
        >
          <TabList mb={2}>
            <Tab>Incomplete</Tab>
            <Tab>Complete</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <UploadPanel />
            </TabPanel>
            <TabPanel>
              <CompletedPanel />
            </TabPanel>
          </TabPanels>
        </Tabs> */}
        <NewPanel />
      </Box>
    </>
  );
}

export default Deposits;
