import React, { useState, useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Center,
  Text,
  Box,
  Button,
  TableContainer,
  Input,
  useToast,
} from "@chakra-ui/react";
import SearchBar from "../SearchBar";
import IncompleteDeposit from "../deposits/NewDeposit";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import useAPI from "../../hooks/useAPI";
import CustomSpinner from "../CustomSpinner";
import { useAuth } from "../auth/AuthProvider";

function NewPanel() {
  const toast = useToast();
  const fileInputRef = useRef(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [query, setQuery] = useState({ complete: false });
  const { token } = useAuth();

  const {
    data: { results: deposits },
    error,
    loading: loadingDeposits,
  } = useAPI(
    "/deposits",
    {
      params: {
        ...query,
      },
    },
    [query]
  );

  const handleFileUpload = (event) => {
    setLoadingFile(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/deposits`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast({
          title: "File successfully uploaded",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast({
          title: "Failed to upload file",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Failed to upload file: ", err);
        setLoadingFile(false);
      });
  };

  return (
    <>
      <Center my={4}>
        <Input
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          hidden
          ref={fileInputRef}
        />
        <Button
          leftIcon={<FiUpload />}
          colorScheme="gray"
          onClick={() => fileInputRef.current.click()}
          size="lg"
          mb={4}
        >
          Upload CSV
        </Button>
      </Center>
      <TableContainer
        borderRadius="xl"
        whiteSpace="normal"
        height="660px"
        overflowY="auto"
        position="relative"
      >
        <Table>
          <Thead position="sticky" top="0" zIndex={1}>
            <Tr>
              <Th>ID</Th>
              <Th>Date</Th>
              <Th>Memo</Th>
              <Th>Amount</Th>
              <Th>Ref No.</Th>
              <Th>Student MAT</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {!loadingFile &&
              !loadingDeposits &&
              deposits &&
              deposits.length > 0 &&
              deposits?.map((item) => <IncompleteDeposit {...item} />)}
          </Tbody>
        </Table>
        {(loadingFile || loadingDeposits) && <CustomSpinner />}
        {!loadingFile &&
          !loadingDeposits &&
          deposits &&
          deposits.length === 0 && (
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"Center"}
              position={"absolute"}
              top={0}
            >
              <Text fontSize="2xl" fontWeight="semibold" color="gray.500">
                No deposits
              </Text>
            </Box>
          )}
      </TableContainer>
    </>
  );
}

export default NewPanel;
