import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Text,
  Heading,
  Button,
  Flex,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"; 

const DeleteModal = ({ isOpen, onClose, title, onDelete }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody m={4}>
          <Button mr={4} onClick={onClose}>
            No
          </Button>
          <Button colorScheme="red" mr={4} onClick={onDelete}>
            Yes
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
