import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Button,
  Heading,
  HStack,
  Image,
} from "@chakra-ui/react";
import logo from "../assets/logo.jpeg";

function NavBar({ activeName }) {
  const navigate = useNavigate();

  const pages = [
    { label: "Applications", href: "/applications" },
    { label: "Deposits", href: "/deposits" },
    { label: "Upload Invoices", href: "/upload_invoices"}
  ];

  return (
    <div id="page">
      <Box bg={"gray.100"} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <HStack
            h="100%"
            onClick={() => navigate("/")}
            cursor="pointer"
          >
            <Image src={logo} h="65%" objectFit={"cover"} />
            <Heading
              ml={1}
              fontWeight="semibold"
              fontSize="xl"
              style={{ fontFamily: "Raleway", whiteSpace: "nowrap" }}
            >
              Jump Finance
            </Heading>
          </HStack>
          <HStack spacing={4}>
            {pages.map((page) => (
              <Button
                key={page.label}
                variant={activeName === page.label ? "solid" : "ghost"}
                onClick={() => navigate(page.href)}
              >
                {page.label}
              </Button>
            ))}
          </HStack>
        </Flex>
      </Box>
    </div>
  );
}

export default NavBar;
