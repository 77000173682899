import React from 'react';
import {
  Box,
  VStack,
  Text,
  Button,
  Image,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';


function CallHistoryCommentModal({ isOpen, onClose, comment }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Comment</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Text>{comment}</Text>
            </ModalBody>
            <ModalFooter>
            <Button onClick={onClose}>Close</Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    );
    }

export default CallHistoryCommentModal;