import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
const xxl = defineStyle({
  height: 100,
  width: 100,
});

const customSpinner = defineStyle({
  thickness: "4px",
  speed: "0.65s",
  emptyColor: "gray.200",
  color: "brand.500",
});

export const spinnerTheme = defineStyleConfig({
  sizes: { xxl },
  variants: { customSpinner },
//   defaultProps: {
//     size: "xxl",
//     variant: "customSpinner",
//   },
});
