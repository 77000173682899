import { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Center,
  Text,
  Box,
  TableContainer,
  HStack,
} from "@chakra-ui/react";
import SearchBar from "../SearchBar";
import useAPI from "../../hooks/useAPI";
import SortSelector from "../selectors/SortSelector";
import OrderSelector from "../selectors/OrderSelector";
import CustomSpinner from "../CustomSpinner";
import { useNavigate } from "react-router-dom";

function PendingPanel() {
  const [query, setQuery] = useState({ status: "pending" });
  const navigate = useNavigate();

  const { data, error, loading } = useAPI(
    "/applications",
    {
      params: {
        ...query,
      },
    },
    [query]
  );

  const apps = data?.results ?? [];
  const totalResults = data?.count;

  return (
    <>
      <HStack spacing={4} mb={4}>
        <SearchBar
          onSearch={(input) => setQuery({ ...query, search: input })}
          placeholder={"Search pending applications"}
        />
        <SortSelector
          sortOptions={[
            { value: "id", label: "ID" },
            { value: "date_applied", label: "Date Applied" },
            { value: "name", label: "Name" },
          ]}
          selectedSort={query.sort}
          onSelect={(sort) => {
            if (sort === null) {
              setQuery({ ...query, sort, order: null });
            } else {
              setQuery({ ...query, sort });
            }
          }}
        />
        <OrderSelector
          selectedOrder={query.order}
          onSelect={(order) => setQuery({ ...query, order })}
        />
      </HStack>
      <TableContainer
        borderRadius="xl"
        whiteSpace="normal"
        height="700px"
        overflowY="auto"
        position="relative"
      >
        <Table size="sm">
          <Thead position="sticky" top="0" zIndex={1}>
            <Tr>
              <Th>ID</Th>
              <Th>Date Applied</Th>
              <Th>Name</Th>
              <Th>MAT</Th>
              <Th>School</Th>
              <Th>Tuition Advance</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!loading &&
              apps &&
              apps.length > 0 &&
              apps.map((app, index) => (
                <Tr
                  key={index}
                  _hover={{ backgroundColor: "gray.100" }}
                  cursor="pointer"
                  onClick={() => navigate("../applications/" + app.id)}
                >
                  <Td>{app.id}</Td>
                  <Td>{app.date_applied?.split("T")[0]}</Td>
                  <Td>{app.first_name + " " + app.last_name}</Td>
                  <Td>{app.mat_number}</Td>
                  <Td>{app.university?.name}</Td>
                  <Td>{app.tuition_amount + app.arrears_amount}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        {loading && <CustomSpinner />}
        {!loading && apps && apps.length === 0 && (
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"Center"}
            position={"absolute"}
            top={0}
          >
            <Text fontSize="2xl" fontWeight="semibold" color="gray.500">
              No applications
            </Text>
          </Box>
        )}
      </TableContainer>
    </>
  );
}

export default PendingPanel;
