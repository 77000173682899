import { useState } from "react";
import OpenPanel from "../Components/panels/OpenApps";
import PendingPanel from "../Components/panels/PendingApps";
import AllPanel from "../Components/panels/AllApps";
import ArrearPanel from "../Components/panels/ArrearsApps";
import CallHistory from "../Components/panels/CallHistory";
import {
  Box,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
} from "@chakra-ui/react";

import NavBar from "../Components/Navbar";

function Applications() {
  const [activeTab, setActiveTab] = useState(2);

  return (
    <>
      <NavBar activeName={"Applications"} />
      <Box m={6}>
        {/* <Heading size="lg" mb={8} fontWeight="semibold" color="black">
          Applications
        </Heading> */}
        <Tabs onChange={(index) => setActiveTab(index)} index={activeTab}>
          <TabList mb={2}>
            <Tab>Open</Tab>
            <Tab>Pending</Tab>
            <Tab>All</Tab>
            <Tab>Arrears</Tab>
            <Tab>Call History</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <OpenPanel />
            </TabPanel>
            <TabPanel>
              <PendingPanel />
            </TabPanel>
            <TabPanel>
              <AllPanel />
            </TabPanel>
            <TabPanel>
              <ArrearPanel/>
            </TabPanel>
            <TabPanel>
              <CallHistory />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}

export default Applications;
