import { useState } from "react";
import ReviewedApp from "../applications/ReviewedApp";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Center,
  Text,
  Box,
  TableContainer,
  HStack,
  Button,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import SearchBar from "../SearchBar";
import useAPI from "../../hooks/useAPI";
import SortSelector from "../selectors/SortSelector";
import OrderSelector from "../selectors/OrderSelector";
import CustomSpinner from "../CustomSpinner";
import universities from "../../data/universities";
import ModalForm from "../ModalForm";
import { useAuth } from "../auth/AuthProvider";
import axios from "axios";
import FilterSelector from "../selectors/FilterSelector";

function AllPanel() {
  // TODO: fetch finance data to check if student is behind on payments
  // TODO: fetch denied and scholarship applications too
  const [query, setQuery] = useState({});
  const { token } = useAuth();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loadingUTGReport, setLoadingUTGReport] = useState(false);
  const [loadingGCReport, setLoadingGCReport] = useState(false);

  const { data, error, loading } = useAPI(
    "/applications",
    {
      params: {
        ...query,
      },
    },
    [query]
  );

  const apps = data?.results ?? [];
  const totalResults = data?.count;

  const statusLabels = {
    open: "Open",
    pending: "Pending",
    approved: "Approved",
    denied: "Denied",
    completed: "Completed",
    prospective_scholarship: "Prospective Scholarship",
    retroactive_scholarship: "Retroactive Scholarship",
    discontinued_studies: "Discontinued Studies"
  };

  const modalData = [
    {
      key: "first_name",
      label: "First Name",
      value: null,
      type: "text",
    },
    {
      key: "last_name",
      label: "Last Name",
      value: null,
      type: "text",
    },
    {
      key: "university",
      label: "University",
      value: null,
      type: "select",
      options: universities,
    },
    {
      key: "mat_number",
      label: "MAT Number",
      value: null,
      type: "text",
    },
    {
      key: "status",
      label: "Status",
      value: null,
      type: "select",
      options: statusLabels,
    },
    {
      key: "graduation_date",
      label: "Graduation Date",
      value: new Date().toISOString().split("T")[0],
      type: "date",
    },
    {
      key: "email",
      label: "Email",
      value: null,
      type: "text",
    },
    {
      key: "phone_number",
      label: "Phone Number",
      value: null,
      type: "text",
    },
    {
      key: "address",
      label: "Address",
      value: null,
      type: "text",
    },
    {
      key: "country",
      label: "Country",
      value: null,
      type: "text",
    },
    {
      key: "region",
      label: "Region",
      value: null,
      type: "text",
    },
    {
      key: "start_date",
      label: "Invoice Start",
      value: new Date().toISOString().split("T")[0],
      type: "date",
    },
    {
      key: "tuition_amount",
      label: "Tuition Amount",
      value: null,
      type: "text",
    },
    {
      key: "arrears_amount",
      label: "Arrears Amount",
      value: null,
      type: "text",
    },
  ];

  const getStudentReport = (university_id) => {
    if (university_id === 1) setLoadingUTGReport(true);
    else setLoadingGCReport(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/applications/report/${university_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "student_report.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        if (university_id === 1) setLoadingUTGReport(false);
        else setLoadingGCReport(false);
      })
      .catch((err) => {
        console.error("Error fetching student report: ", err);
        if (university_id === 1) setLoadingUTGReport(false);
        else setLoadingGCReport(false);
      });
  };

  const handleCreate = (data) => {
    // console.log(data);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/applications`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // TODO: refactor toasting
        onClose();
        toast({
          title: "Application successfully created",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        onClose();
        toast({
          title: "Failed to create application",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error creating application: ", err);
      });
  };

  return (
    <>
      <Center my={2}>
        <Button
          leftIcon={<AddIcon />}
          onClick={onOpen}
          colorScheme="gray"
          size="lg"
          mb={4}
        >
          Add Application
        </Button>
        {/* <Button
          onClick={() => getStudentReport(1)}
          colorScheme="gray"
          size="lg"
          mb={4}
          ml={8}
          isLoading={loadingUTGReport}
          loadingText="Downloading..."
        >
          Download UTG Student Report
        </Button>
        <Button
          onClick={() => getStudentReport(2)}
          colorScheme="gray"
          size="lg"
          mb={4}
          ml={8}
          isLoading={loadingGCReport}
          loadingText="Downloading..."
        >
          Download GC Student Report
        </Button> */}
        <ModalForm
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={handleCreate}
          title="Add Application"
          data={modalData}
        />
      </Center>
      <HStack spacing={4} mb={4}>
        <SearchBar
          onSearch={(input) => setQuery({ ...query, search: input })}
          placeholder={"Search all applications"}
        />
        <SortSelector
          sortOptions={[
            { value: "id", label: "ID" },
            { value: "date_applied", label: "Date Applied" },
            { value: "name", label: "Name" },
          ]}
          selectedSort={query.sort}
          onSelect={(sort) => {
            if (sort === null) {
              setQuery({ ...query, sort, order: null });
            } else {
              setQuery({ ...query, sort });
            }
          }}
        />
        <OrderSelector
          selectedOrder={query.order}
          onSelect={(order) => setQuery({ ...query, order })}
        />
        <FilterSelector
          filterName="Status"
          filterOptions={statusLabels}
          selectedFilter={query.status}
          onSelect={(status) => {
            setQuery({ ...query, status });
          }}
        />
      </HStack>
      {/* TODO: make table container reusable component */}
      <TableContainer
        borderRadius="xl"
        whiteSpace="normal"
        height="625px"
        overflowY="auto"
        position="relative"
      >
        <Table size="sm">
          <Thead position="sticky" top="0" zIndex={1}>
            <Tr>
              <Th>ID</Th>
              <Th>Date Applied</Th>
              <Th>Name</Th>
              <Th>MAT</Th>
              <Th>School</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!loading &&
              apps &&
              apps.length > 0 &&
              apps.map((app, index) => <ReviewedApp key={index} {...app} />)}
          </Tbody>
        </Table>
        {loading && <CustomSpinner />}
        {!loading && apps && apps.length === 0 && (
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"Center"}
            position={"absolute"}
            top={0}
          >
            <Text fontSize="2xl" fontWeight="semibold" color="gray.500">
              No applications
            </Text>
          </Box>
        )}
      </TableContainer>
    </>
  );
}

export default AllPanel;
