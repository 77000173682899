// for some reason, withDefaultProps doesn't work with focusBorderColor
import { inputAnatomy, selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig: inputDefineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const inputDefaultProps = {
  focusBorderColor: "brand.500",
};

export const inputTheme = inputDefineMultiStyleConfig({ defaultProps: inputDefaultProps });


const { defineMultiStyleConfig: selectDefineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const selectDefaultProps = {
  focusBorderColor: "brand.500",
};

export const selectTheme = selectDefineMultiStyleConfig({ defaultProps: selectDefaultProps });
