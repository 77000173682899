import React from 'react';
import {
  Box,
  VStack,
  Text,
  Button,
  Image,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';

const DisplayInvoiceModal = ({ isOpen, onClose, imgURL }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invoice</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="center" justify="center" w="full">
            <Box position="relative" mt="30px" mb="30px">
              {imgURL != "logo" ? (
                <Image
                  src={imgURL}
                  objectFit="contain"
                  boxSize="300px"
                />
              ) : (
                <Text>No invoice uploaded.</Text>
              )}
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DisplayInvoiceModal;