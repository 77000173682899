import React from "react";
import { useState } from "react";
import {
  Box,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
} from "@chakra-ui/react";
import CompletedPanel from "../Components/panels/CompletedDeposits";
import NewPanel from "../Components/panels/UploadInvoice";
import NavBar from "../Components/Navbar";

function Invoices() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <NavBar activeName={"Upload Invoices"} />
      <Box m={6}>
        <NewPanel />
      </Box>
    </>
  );
}

export default Invoices;
