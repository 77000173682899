import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import { tabsTheme } from "./tabs";
import { tableTheme } from "./table";
import { inputTheme, selectTheme } from "./input";
import { spinnerTheme } from "./spinner";

const theme = extendTheme(
  {
    colors: {
      brand: {
        // using the base color, #13BD95, I looked up shades and tints
        50: "#C4EFE5",
        100: "#89DECA",
        200: "#6CD6BD",
        300: "#4ECEB0",
        400: "#31C5A2",
        500: "#13BD95",
        600: "#11A582",
        700: "#0E8E70",
        800: "#0C765D",
        900: "#0A5F4B",
      },
    },
    components: {
      Tabs: tabsTheme,
      Table: tableTheme,
      Input: inputTheme,
      Select: selectTheme,
      Spinner: spinnerTheme,
    },
  },
  withDefaultColorScheme({
    colorScheme: "brand",
    components: ["Button", "Tabs"],
  })
);

export default theme;
