import { tableAnatomy } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
} from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  thead: {
    bgColor: "brand.300",
    height: 14,
    whiteSpace: "normal",
  },
});

// const defaultProps = {
//   size: "sm",
// };

export const tableTheme = defineMultiStyleConfig({ baseStyle });
