import React, { useState } from 'react';
import {
  Box,
  VStack,
  Text,
  Button,
  Image,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import upload_invoice from '../assets/upload_invoice.png';

const InvoiceModal = ({ isOpen, onClose, onSubmit }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const toast = useToast();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 50 * 1024 * 1024) { // 50 mb
        setFileError("File size exceeds 50MB");
        setFileName(null);
        setFileURL(null); // Clear the file URL
      } else {
        setFileError(null);
        setFileName(file.name);
        setFile(file);
        setFileURL(URL.createObjectURL(file)); // Set the file URL
      }
    }
  };

  const handleUpload = () => {
    onSubmit(file, fileName);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Invoice</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="center" justify="center" w="full">
            <Box position="relative" mt="30px" mb="30px">
              <Image
                src={fileURL ? fileURL : upload_invoice} // Now displays the uploaded image if available
                objectFit='contain'
                boxSize="300px"
              />
              <Input
                id="file-upload"
                type="file"
                accept="image/jpg,image/jpeg,image/png,application/pdf"
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                opacity="0"
                cursor="pointer"
                onChange={handleFileChange}
              />
            </Box>
            {fileError ? (
              <Text color="red.500" mb={5}>{fileError}</Text>
            ) : (
              fileName && (
                <Box mb={5} textAlign="center">
                  <Text fontSize="md">Attached File: {fileName}</Text>
                </Box>
              )
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={handleUpload}>
            Submit
          </Button>
          <Button colorScheme="red" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InvoiceModal;