import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Tr, Th, Td, Badge } from "@chakra-ui/react";

function ReviewedApp({
  id,
  date_applied,
  first_name,
  last_name,
  university,
  mat_number,
  status,
}) {
  const navigate = useNavigate();

  const statuses = {
    open: { label: "Open", color: "green" },
    pending: { label: "Pending", color: "yellow" },
    approved: { label: "Approved", color: "green" },
    denied: { label: "Denied", color: "red" },
    // payments_received: { label: "Payments received", color: "green" },
    // payments_behind: { label: "Payments behind", color: "yellow" },
    prospective_scholarship: { label: "Scholarship", color: "blue" },
    retroactive_scholarship: {
      label: "Retroactive scholarship",
      color: "blue",
    },
    completed: { label: "Completed", color: "blue" },
    discontinued_studies : {label: 'Discontinued Studies', color: 'red'}
  };

  return (
    <Tr
      _hover={{ backgroundColor: "gray.100" }}
      cursor="pointer"
      onClick={() => navigate("../applications/" + id)}
    >
      <Td>{id}</Td>
      <Td>{date_applied?.split("T")[0]}</Td>
      <Td>{first_name + " " + last_name}</Td>
      <Td>{mat_number}</Td>
      <Td>{university?.name}</Td>
      {/* TODO: change badge for students to either "on time" or "behind" depending on their payment status */}
      <Td>
        {status ? (
          <Badge colorScheme={statuses[status]?.color} p={2} m={0}>
            {statuses[status]?.label}
          </Badge>
        ) : (
          <Badge colorScheme={"gray"}>N/A</Badge>
        )}
      </Td>
    </Tr>
  );
}

export default ReviewedApp;
